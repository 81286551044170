<script setup>

import {route} from "ziggy-js";
import NavLink from "@/Components/NavLink.vue";

const props = defineProps({
    contentClass: {
        type: Array,
        default: []
    },
    productName: {
        type: String,
    },
    lotName: {
        type: String,
    },
    productId: {
        type: Number
    },
    inHeader: {
        type: Boolean,
        default: false,
    }
})

</script>

<template>
    <div :class="[...contentClass]" class="border-b border-slate-300 dark:border-slate-700 border-dashed -ml-2.5 -mr-2.5">
        <div class="px-2.5 tracking-wider">
            <div
                class="px-2.5 py-1.25 text-red-500 border-b border-slate-300 dark:border-slate-700 font-sans font-extrabold w-fit border-dashed text-xl">
                {{ $t('Whoops!') }} :(
            </div>

            <div v-if="inHeader" class="dark:text-slate-200 px-2.5 pt-1.5 pb-2.5 text-base">{{ $t('Seems like we don’t have this right now.') }}
                <br>
                <span v-html="
                  $t('You can sell your :lotNameBold :productName by clicking the “Sell :lotName” button at the top of the page.', {
                      productName: `<b>${productName}</b>`,
                      lotNameBold: `<b>${lotName ?? ''}</b>`,
                      lotName: lotName ?? '',
                  })">
                </span>
            </div>
            <div v-else class="dark:text-slate-200 px-2.5 pt-1.5 pb-2.5 text-base">
                {{ $t('Seems like we don’t have this yet.') }}
                <br>
                {{$t('Click') }}
                <NavLink
                    :href="props.productId ? route('user.offers.selectLot', {product_id: props.productId}) : route('user.offers.selectProduct')"
                    class="text-blue-600 underline cursor-pointer">
                    {{ $t('HERE') }}
                </NavLink>
                , {{ $t('to add new item to catalog.') }}

            </div>
        </div>
    </div>

</template>

<style scoped>

</style>
